import React from 'react';

import ShareProjectFunctionality from './ShareProjectFunctionality/ShareProjectFunctionality';
import { ShareProjectUsers } from './ShareProjectGeneralSettings/ShareProjectGeneralSettings';

export default ({ formData, onChangeInputHandler, isShared, shareDetails, projectSharingStep, reloadProject }) => {
  if (projectSharingStep === 'users') {
    return (
      <ShareProjectUsers
        formData={formData}
        reloadProject={reloadProject}
        shareDetails={shareDetails}
        onChangeInputHandler={onChangeInputHandler} />
    )
  } else if (projectSharingStep === 'access') {
    return (
      <ShareProjectFunctionality
        formData={formData}
        isShared={isShared}
        onChangeInputHandler={onChangeInputHandler} />
    )
  } else return null
}