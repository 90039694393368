interface Props {
  accessControl: any
  isProjectSharedWith: boolean
  onEditModal?: () => void
}

export const ProjectInternalUsersAccess = ({ accessControl, onEditModal, isProjectSharedWith }: Props) => {
  if (accessControl && onEditModal && !isProjectSharedWith) {
    if (accessControl.accessType === 1) {
      return (
        <div className="alert alert-walr d-flex justify-content-between mx-4 shadow-sm" role="alert">
          <div className="d-flex align-items-center">
            <i className="far fa-lg fa-info-circle mr-4 text-primary"></i>
            <span className="text-primary">Only you can access this project.</span>
          </div>
          <button onClick={onEditModal} className="btn btn-walr">
            <i className="fas fa-user-cog mr-2"></i>
            <span>Edit</span>
          </button>
        </div>
      )
    } else if (accessControl.accessType === 2) {
      return (
        <div className="alert alert-walr d-flex justify-content-between mx-4 shadow-sm" role="alert">
          <div className="d-flex align-items-center">
            <i className="far fa-lg fa-info-circle mr-4 text-primary"></i>
            <span className="text-primary">Everyone in your organisation can access this project.</span>
          </div>
          <button onClick={onEditModal} className="btn btn-walr">
            <i className="fas fa-users-cog mr-2"></i>
            <span>Edit</span>
          </button>
        </div>
      )
    } else if (accessControl.accessType === 3) {
      return (
        <div className="alert alert-walr d-flex justify-content-between align-items-center mx-4 shadow-sm" role="alert">
          <i className="far fa-lg fa-info-circle mr-4 text-primary"></i>
          <div className="d-flex flex-column flex-grow-1">
            <span className="text-primary">The following users/groups from your organisation can access this project:</span>
            <div className="pr-5">
              {
                accessControl.accessInfo.map((el: any, key: number) => (
                  <span key={key} className="badge badge-walr strong p-2 my-1 mr-2">
                    <span>{el.type === 'user' ? el.email : el.name}</span>
                  </span>
                ))
              }
            </div>
          </div>
          <div className="d-flex">
            <button onClick={onEditModal} className="btn btn-walr">
              <i className="fas fa-users-cog mr-2"></i>
              <span>Edit</span>
            </button>
          </div>
        </div>
      )
    } else return null
  } else return null
}