import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../../../store/reducers/rootReducer';

interface Props {
  shareDetails: any
}

export const ShareProjectAccessTabContent = ({ shareDetails }: Props) => {
  const dispatch = useDispatch()
  const [user, setUser] = useState<string>('')
  const { regularAccounts, invalidRegularUsers } = useSelector((theState: RootState) => theState.projectExternalShareStateReducer);

  useEffect(() => {
    const sharedRegularAccounts = shareDetails.shareUsers.filter((shareUser: any) => !shareUser.isSubAccountUser)
    if (sharedRegularAccounts.length > 0) {
      dispatch({ type: 'SET_SHARE_PROJECT_REGULAR_ACCOUNTS', payload: sharedRegularAccounts.map((item: any) => item.email) })
    }
  }, [dispatch, shareDetails])

  const onDeleteUserHandler = (selectedAccount: string) => {
    const updatedRegularAccounts = [...regularAccounts]
    updatedRegularAccounts.splice(updatedRegularAccounts.indexOf(selectedAccount), 1)
    dispatch({ type: 'SET_SHARE_PROJECT_REGULAR_ACCOUNTS', payload: updatedRegularAccounts })
  }

  const onAddUserHandler = (e: any) => {
    e.preventDefault()
    if (!regularAccounts.includes(user)) {
      setUser('')
      dispatch({ type: 'SET_SHARE_PROJECT_REGULAR_ACCOUNTS', payload: [...regularAccounts, user] })
    } else {
      alert('already exists')
    }
  }

  const returnStatus = (account: string) => {
    return shareDetails.shareUsers.find((item: any) => item.email === account).status
  }

  const returnStatusColor = (account: string) => {
    const activeAccount = shareDetails.shareUsers.find((item: any) => item.email === account)
    if (activeAccount.status === 'pending') {
      return 'primary'
    } else if (activeAccount.status === 'accepted') {
      return 'success'
    } else {
      return 'danger'
    }
  }

  return (
    <Fragment>
      <h6 className="text-muted text-center my-4">Regular accounts are users that have full access to Walr Platform</h6>

      <form onSubmit={onAddUserHandler}>
        <div className="input-group mb-3">
          <input
            value={user}
            type="email"
            className="form-control"
            placeholder="Email address"
            onChange={(e) => setUser(e.target.value)} />
          <div className="input-group-append">
            <button className="btn btn-success px-4" type="submit">
              <i className="fas fa-plus-square mr-2"></i>
              <span>Add</span>
            </button>
          </div>
        </div>
      </form>

      {
        regularAccounts.length > 0 ?
          <table className="table table-striped table-bordered">
            <thead className="text-center">
              <tr>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col" className="w-25"></th>
              </tr>
            </thead>
            <tbody>
              {
                regularAccounts.map((account: string, key: number) => (
                  <tr key={key}>
                    <td
                      style={{ verticalAlign: 'middle' }}>
                      <span>{account}</span>
                      {
                        invalidRegularUsers.find((user: any) => user.id === account) &&
                        <i className={`fas fa-exclamation-circle text-danger ml-3`}></i>
                      }
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {
                        shareDetails.shareUsers.find((item: any) => item.email === account) ?
                          <span className={`badge badge-pill badge-${returnStatusColor(account)} text-capitalize align-middle py-2 px-3`}>{returnStatus(account)}</span>
                          :
                          <span></span>
                      }
                    </td>
                    {
                      !shareDetails.shareUsers.find((item: any) => item.email === account) ?
                        <td style={{ verticalAlign: 'middle' }} onClick={() => onDeleteUserHandler(account)} className="cursor-pointer">
                          <i className="fas fa-lg fa-user-minus text-danger"></i>
                        </td>
                        :
                        <td></td>
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
          :
          <p className="text-center text-disabled my-5 h5">No accounts have been added</p>
      }
    </Fragment>
  )
}