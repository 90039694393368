import { useState } from 'react';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { useSelector } from 'react-redux';

import { Avatar } from '../Avatar/Avatar';
import projectAddActions from './projectAddActions.json';
import ProjectSharedUsers from '../ProjectSharedUsers/ProjectSharedUsers';
import PendingInvitationsContent from '../PendingInvitationsContent/PendingInvitationsContent';
import type { ShareDetails } from '../../../interfaces/projectDetailsInterfaces/shareDetailsInterfaces';
import { ProjectInternalUsersAccess } from '../ProjectInternalUsersAccess/ProjectInternalUsersAccess';
import type { PendingInvitation } from '../../../interfaces/pendingInvitationInterface/pendingInvitationInterface';
import type { ProjectDetails, ProjectTag } from '../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';
import RemoveProjectSharingConfirmModal from '../ShareProjectModal/ShareProjectContent/RemoveProjectSharingConfirmModal/RemoveProjectSharingConfirmModal';
import type { RootState } from '../../../store/reducers/rootReducer';
import type { User } from '../../../interfaces/userInterface/userInterface';

interface Props {
  user: User;
  icon: string
  title: string
  image?: string | null
  avatarBackgroundColor?: string | null
  shouldShowAddButton?: boolean
  hasShare?: boolean
  hasImport?: boolean
  hasCreate?: boolean
  hasActions?: boolean
  projectDetails?: boolean
  onImport?: () => void
  onCreate?: () => void
  onAddFile?: () => void
  onEditModal?: () => void
  reloadProject?: () => void
  onShowInvite?: () => void
  onCreateClick?: (e: TODO) => void
  onShowShareProject?: (val: TODO) => void
  shareDetails?: ShareDetails
  projectData?: ProjectDetails
  pendingInvitations?: PendingInvitation[]
}

type ProjectAddActions = {
  text: string;
  icon?: string;
  disabled?: boolean
  id: "survey" | "surveyItem" | "file" | "pinboard" | "panel" | "workflow" | "audiences";
}

export const Header = ({ projectData, onCreate, onCreateClick, shouldShowAddButton, onEditModal, hasCreate, hasShare, hasImport, projectDetails, title, image, icon, avatarBackgroundColor, onImport, onShowShareProject, shareDetails, pendingInvitations, hasActions, reloadProject, user }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [showRemoveSharingModal, setShowRemoveSharingModal] = useState(false)
  const isProjectSharedWith = !!shareDetails?.sharedWithDetails
  const isProjectShared = !!shareDetails?.isShared
  const showAddButton = !!(shouldShowAddButton && shouldShowAddButton)

  const returnProjectActions = () => {
    let projectActions = projectAddActions as unknown as ProjectAddActions[];

    if (!user?.enterpriseSettings?.hasPanelAccess) {
      projectActions = projectActions.filter(el => el.id !== 'panel')
    }
    if (!user?.enterpriseSettings?.hasToolsAccess) {
      projectActions = projectActions.filter(el => el.id !== 'workflow')
    }
    if (!user?.enterpriseSettings?.hasSurveyAccess) {
      projectActions = projectActions.filter(el => el.id !== 'survey' && el.id !== 'surveyItem')
    }
    if (!user?.enterpriseSettings?.hasPinboardAccess) {
      projectActions = projectActions.filter(el => el.id !== 'pinboard')
    }
    if (!user?.enterpriseSettings?.hasAudienceAccess) {
      projectActions = projectActions.filter(el => el.id !== 'audiences')
    }

    return projectActions
  }

  const shareActionButtons = [
    {
      "actionName": "Setup sharing",
      "action": "share",
      "icon": "far fa-share-square"
    },
    {
      "actionName": "Remove sharing",
      "action": "removeSharing",
      "disabled": !isProjectShared,
      "icon": "far fa-trash-alt"
    }
  ]

  const itemRender = (props: { item: TODO }) => {
    return (
      <div className={`d-flex ${props.item.id !== 'surveyItem' ? 'align-items-center justify-content-between' : 'px-3'}`}>
        {props.item.icon && <span className={`k-icon k-i-${props.item.icon}`} />}
        <span className="ml-2">{props.item.text}</span>
      </div>
    );
  };

  const onShareItemClickHandler = (e: { item: { action: string } }) => {
    if (e.item.action === 'share' && onShowShareProject) {
      onShowShareProject({ show: true, step: 'access' })
    } else if (e.item.action === 'removeSharing') {
      setShowRemoveSharingModal(true)
    }
  }

  return (
    <div className="container-fluid">
      <div className="d-flex gap-lg flex-column  row">
        <div className="d-flex flex-column col-lg-12 p-10 bg-walr gap-lg">
          <div className={`row no-gutters ${hasActions ? 'justify-content-between align-items-center' : null}`}>
            <div className="col-sm-6 d-flex gap-lg align-items-center">
              <Avatar
                backgroundColor={avatarBackgroundColor}
                image={image}
                icon={icon}
                color={avatarBackgroundColor ? '' : isProjectSharedWith ? "success" : "primary"}
                class={`${projectDetails ? "avatar-xl" : "avatar-lg"} m-0`} />
              <div className="d-flex flex-column gap-sm">
                <h1 className="heading-dashboard text-primary w-100 h2">
                  {title}
                </h1>
                {projectData?.details && (
                  <p className="m-0 line-clamp-2">
                    {projectData?.details?.description}
                  </p>
                )}
              </div>
            </div>
            <div className="col-sm-6 d-flex justify-content-end flex-wrap gap-md">
              {/* Import button in Project Details page */}
              {
                hasImport &&
                <Button
                  id="import"
                  className="btn btn-primary"
                  icon="fa fa-fw fas fa-cloud-upload-alt"
                  onClick={onImport}
                  disabled={!!(isProjectSharedWith && shareDetails?.sharedWithDetails?.accessMode === 'readonly')}>Import</Button>
              }
              {/* Add button in Project Details page */}
              {
                showAddButton &&
                <DropDownButton
                  text="Add"
                  id="project-create"
                  icon="fa fa-fw fas fa-plus-circle"
                  buttonClass="btn btn-outline-primary"
                  items={returnProjectActions()}
                  itemRender={itemRender}
                  onItemClick={onCreateClick}
                  popupSettings={{ animate: false, popupClass: "create-dropdown-button py-2" }}
                  disabled={!!(isProjectSharedWith && shareDetails?.sharedWithDetails?.accessMode === 'readonly')}
                />
              }
              {/* Edit button in Project Details page */}
              {
                hasImport &&
                <Button
                  disabled={isProjectSharedWith}
                  className="btn btn-outline-primary"
                  icon="fa fa-fw fas fa-edit"
                  onClick={onEditModal}>Edit
                </Button>
              }
              {/* Sharing button in Project Details page */}
              {
                hasShare &&
                <DropDownButton
                  text={'Sharing'}
                  icon="fas fa fa-share"
                  className="analyze-actions-button"
                  buttonClass="btn btn-outline-primary"
                  items={shareActionButtons}
                  disabled={!!isProjectSharedWith}
                  popupSettings={{ popupClass: 'actions-dropdown' }}
                  onItemClick={onShareItemClickHandler}
                  itemRender={(props: TODO) => (
                    <button type='button' disabled={props.item.disabled} className="btn bg-transparent p-0 d-flex justify-content-between align-items-center">
                      <i className={`${props.item.icon} mr-3`} />
                      <span>{props.item.actionName}</span>
                    </button>
                  )}
                />
              }
              {/* Create button in /projects page */}
              {
                hasCreate && !user?.isSubAccount ?
                  <Button
                    onClick={onCreate}
                    id="create"
                    icon="fa fa-fw fas fa-plus-circle"
                    className="btn btn-primary d-flex align-items-center">Create
                  </Button>
                  : null
              }
            </div>
          </div>
          <div>
            {
              projectData?.details?.tags?.map((tag: ProjectTag) => (
                <span key={tag.id} className="badge badge badge-primary p-2" style={{ fontWeight: 500 }}>{tag.name}</span>
              ))
            }
          </div>
        </div>
        <div className="col-md-12">
          {
            pendingInvitations &&
            <PendingInvitationsContent token={token} pendingInvitations={pendingInvitations} />
          }
          {
            isProjectSharedWith &&
            <div className="d-flex align-items-center alert alert-success">
              <span style={{ fontWeight: 500 }} className="badge badge badge-success p-2 m-1">{shareDetails?.sharedWithDetails?.sharedByName}</span>
              <p className="ml-1 mb-0">has shared this project with you</p>
            </div>
          }
          <ProjectInternalUsersAccess
            onEditModal={onEditModal}
            isProjectSharedWith={isProjectSharedWith}
            accessControl={projectData?.details?.accessControl}
          />
          {
            isProjectShared && shareDetails?.shareUsers &&
            <ProjectSharedUsers
              shareDetails={shareDetails}
              onShowShareProject={onShowShareProject} />
          }
          {
            showRemoveSharingModal &&
            <RemoveProjectSharingConfirmModal
              reloadProject={reloadProject}
              projectId={projectData?.details?.id}
              onHide={() => setShowRemoveSharingModal(false)}
            />
          }
        </div>
      </div>
    </div >
  );
};