import React, { useState } from 'react';

import { SideBar } from '../../../../shared/SideBar/SideBar';
import { FielworkDashboardReportTabContent } from './FielworkDashboardReportTabContent/FielworkDashboardReportTabContent';

interface IProps {
  closeReport: (el: boolean) => void
  dashboardData: any
}

export const FielworkDashboardReport: React.FC<IProps> = ({ closeReport, dashboardData }) => {
  const [tabSelected, setTabSelected] = useState(0)
  const tabs = [
    { title: 'Screen out', id: 'screenOut' },
    { title: 'Drop out', id: 'dropOut' },
    { title: 'Quota full', id: 'quotaFull' },
    { title: 'Quality fail', id: 'qualityFail' },
  ]
  return (
    <SideBar
      header={{ show: true, title: "Reports" }}
      onHide={() => closeReport(false)}
    >
      <div>
        <ul className="nav nav-pills content-tabs flex-grow-1 border-top border-bottom pl-2">
          {
            tabs.map((el, id) => (
              <li onClick={() => setTabSelected(id)} key={id} className="nav-item">
                <span className={`nav-link ${tabSelected === id && 'active'}`}>{el.title}</span>
              </li>
            ))
          }
        </ul>
        <FielworkDashboardReportTabContent tabType={tabs[tabSelected].id} dashboardData={dashboardData} />
      </div>
    </SideBar>
  )
}
