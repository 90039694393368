import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { process } from '@progress/kendo-data-query';
import { useHistory, useParams } from "react-router-dom";
import { DropDownButton } from '@progress/kendo-react-buttons';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';

import itemActions from './menuItemActions.json';
import usePrevious from '../../../shared/customHooks/usePrevious';
import { GridNoData } from '../../../shared/GridNoData/GridNoData';
import { InProgressOverlay } from '../../../shared/InProgressOverlay/InProgressOverlay';
import { ColumnValue } from '../../../shared/helpers/project/ReturnTableColumns/ColumnValue'
import { returnTableIcon } from '../../../shared/helpers/project/returnTableIcon/returnTableIcon';
import EditOutcomesModal from '../../../shared/Modals/EditOutcomesModal/EditOutcomesModal';
import { ExportNonCompletesModal } from './ExportNonCompletesModal/ExportNonCompletesModal';
import { returnFilterData } from '../../../shared/helpers/project/returnFilterData/returnFilterData';
import setStatus from '../../../shared/helpers/project/returnProjectStatusData/returnProjectStatusData';
import { sortProjectDocuments } from '../../../shared/helpers/sortProjectDocuments/sortProjectDocuments';
import { returnColumnDataInfo } from '../../../shared/helpers/project/returnColumnDataInfo/returnColumnDataInfo';
import { returnSortDirectionIcon } from '../../../shared/helpers/returnSortDirectionIcon/returnSortDirectionIcon';
import fileManagerDownloadHandler from '../../../shared/helpers/fileManagerDownloadHandler/fileManagerDownloadHandler';
import { returnUpdatedSortDirection } from '../../../shared/helpers/returnUpdatedSortDirection/returnUpdatedSortDirection';
import { fetchGetBlob, fetchPost, fetchPostResOrJson, fetchPutResOrJson } from '../../../../services/services';

export const ProjectDocumentsTable = ({ shareDetails, projectDocuments, showConfirmActionModal, showEditDocumentModal, showExportProjectDocument, showAddSurveyFromProjectModal, projectId, setProjectDetails, isPageable }) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.tokenStateReducer);

  const [showImportFileModal, setShowImportFileModal] = useState(false)
  const [uploadSurveyId, setUploadSurveyId] = useState(null)
  const [datasetsState, setDatasetsState] = useState(null) // state for project documents
  const [originalDatasetsState, setOriginalDatasetsState] = useState([])
  const [sortType, setSortType] = useState({ sortBy: 'date', direction: 'desc' })
  const [filterByType, setFilterByType] = useState("Document name")
  const [dateRange, setDateRange] = useState({ start: null, end: null })
  const availableDocumentTypes = [...new Set(originalDatasetsState.map(item => item.type).sort())]
  const [selectedDocumentType, setSelectedDocumentType] = useState([])
  const [openNonCompletesModal, setOpenNonCompletesModal] = useState(false);
  const [nonCompletesBody, setNonCompletesBody] = useState({
    datasetName: "",
    includeStatus: {
      complete: true,
      screened: false,
      quotaFull: false,
      qualityFail: false,
      dropout: false,
      archived: false,
    }
  });
  const prevProjectDocuments = usePrevious(projectDocuments)
  const iconByDocumentType = { audiences: "fas fa-users", datasets: "fas fa-database", surveys: "fas fa-poll-h", workflows: "fas fa-cogs", samples: "fas fa-file-alt", files: "fas fa-file", pinboards: "fas fa-solid fa-table", }
  const [itemClicked, setItemClicked] = useState()
  const searchInput = useRef();
  const createDataState = (dataState, data) => {
    return {
      ...data,
      result: process(data.projects.slice(0), dataState),
      dataState: dataState
    };
  }
  const cloneAudienceObj = {
    audienceId: "",
    projectId: ""
  }

  const dataStateChange = (event) => {
    setDatasetsState(createDataState(event.dataState, datasetsState))
  }

  useEffect(() => {
    if (projectDocuments !== prevProjectDocuments) {
      const updatedSortProjectDocuments = sortProjectDocuments(projectDocuments, sortType.direction, sortType.sortBy)
      setDatasetsState({
        result: process(updatedSortProjectDocuments.projectsDocuments.documents.slice(0), { take: 20, skip: 0 }),
        dataState: { take: 20, skip: 0 },
        projects: updatedSortProjectDocuments.projectsDocuments.documents
      })
      setOriginalDatasetsState(projectDocuments)
    }
  }, [projectDocuments, prevProjectDocuments, sortType])

  const selectAction = (e, item) => {
    if (e.item.text === 'Delete' || e.item.text === 'Edit') {
      const projectId = params.name;
      const id = item.id;
      if (e.item.text === 'Delete') {
        showConfirmActionModal(projectId, id, item.name, item.type)
      } else {
        showEditDocumentModal(projectId, id, item.name, item.type, item.description, item.tags, item._etag, item.dataCollectionVersion)
      }
    } else if (e.item.text === 'Copy') {
      showAddSurveyFromProjectModal(item);
    } else if (e.item.text === 'Analyze') {
      const location = history.location.pathname
      let updatedPathname = `${location}/${item.id}/analyze/datasets`
      dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: item.id, name: item.name } })
      if (item.type === 'surveys') {
        updatedPathname = `${location}/${item.id}/analyze/surveys`
      }
      history.push({ pathname: updatedPathname })
    } else if (e.item.text === 'Open') {
      const returnEndpoint = () => {
        switch (item.type) {
          case "surveys":
            return "survey-builder"
          case "workflow":
            return "workflows"
          case "audiences":
            return "audiences"
          case "pinboards":
            return "pinboards"
          default:
            return "panel"
        }
      }
      const location = history.location.pathname
      dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: item.id, name: item.name } })

      if (item.type === "audiences") {                                            // Solved merged conflict, changed inline if-then
        history.push({ pathname: `${location}/${returnEndpoint()}/${item.id}` })  // Different history logic for audiences, cause of backend limitations
      } else {
        if (item.type === "pinboards") {
          dispatch({ type: "RESET_PINBOARD_DATA" })
        }
        history.push({ pathname: `${location}/${item.id}/${returnEndpoint()}` })
      }

    } else if (e.item.text === 'Download') {
      if (item.type === 'files') {
        fetchGetBlob(`projects/${projectId}/files/${item.id}/download`, token)
          .then(res => {
            if (res) {
              fileManagerDownloadHandler(res, { displayName: item.name })
            }
          })
      }
    } else if (e.item.text === 'Export') {
      showExportProjectDocument(projectId, item.id, item.type)
    } else if (e.item.text === 'Edit data') {
      setItemClicked(item)
      setUploadSurveyId(item.id)
      setShowImportFileModal(true)
    } else if (e.item.text === "Status report") {
      fetchPutResOrJson(`/projects/${projectId}/surveys/${item.id}/respondents/status-report`, token)
        .then(res => {
          if (res?.error) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : "Something went wrong" } })
          } else {
            dispatch({ type: 'SHOW_SUCCESS_NOTIFICATION', payload: { msg: res.error ? res.error : "Status reported downloaded" } })
          }
        })
    } else if (e.item.text === "Clone") {
      cloneAudienceObj.audienceId = item.id
      cloneAudienceObj.projectId = projectId
      fetchPostResOrJson(`au/a/projects/${projectId}/audiences/from-audience`, token, cloneAudienceObj).then(async (res) => {
        const response = await res.json()
        setProjectDetails((prev) => ({ ...prev, documents: [...prev.documents, { ...response, type: "audiences" }] }))
      })
    } else if (e.item.text === "Create dataset") {
      setUploadSurveyId(item.id)
      setOpenNonCompletesModal(true)
    }
  }

  const onFilterChangeHandler = (e, type) => {
    const filterData = returnFilterData(type, null, [], e, originalDatasetsState, e.target.value, null);
    setDatasetsState(filterData)
  }

  const clearFilterInputs = () => {
    if (filterByType === "Document name" || filterByType === "Created by") {
      searchInput.current.value = null;
      setSelectedDocumentType([])
    } else {
      setDateRange(null)
      setSelectedDocumentType([])
    }
    onFilterChangeHandler({ target: { value: "" } }, "clear")
  }

  const setDatasetBreadCrumb = (item) => {
    if (item.type.includes('datasets') || item.type === 'surveys' || item.type === 'samples' || item.type === 'workflows' || item.type === 'pinboards') {
      dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: item.id, name: item.name } })
    }
  }

  const updateSortType = (sortBy) => {
    const updatedDirectionData = returnUpdatedSortDirection(sortType, sortBy)
    const updatedSortProjectDocuments = sortProjectDocuments(datasetsState.projects, updatedDirectionData.updatedDirection, sortBy)
    setSortType({
      sortBy: sortBy,
      direction: updatedDirectionData.updatedDirectionIcon
    })
    setDatasetsState({
      result: process(updatedSortProjectDocuments.projectsDocuments.documents.slice(0), { take: 20, skip: 0 }),
      dataState: { take: 20, skip: 0 },
      projects: updatedSortProjectDocuments.projectsDocuments.documents
    })
  }

  const bulletValueAxis = {
    min: 0,
    max: 100,
    plotBands: [{
      from: 0, to: 15, color: '#ef4c52', opacity: 0.15
    }, {
      from: 15, to: 50, color: '#f1e42f', opacity: 0.3
    }, {
      from: 50, to: 100, color: '#6bb756', opacity: 0.15
    }]
  };

  const analysisTypes = shareDetails.sharedWithDetails?.shareFunctionalities?.analysisTypes
  if (analysisTypes) {
    const enabledTypes = Object.keys(analysisTypes).filter(type => analysisTypes[type] === true)
    if (enabledTypes.length === 0) {
      const datasetsActions = itemActions.document.datasets
      const analyzeItem = datasetsActions.find(item => item.text === 'Analyze')
      if (analyzeItem) {
        const analyzeItemIndex = datasetsActions.indexOf(analyzeItem)
        datasetsActions.splice(analyzeItemIndex, 1)
      }
    }
  }

  if (!datasetsState) {
    return (
      <InProgressOverlay
        type="fullscreen"
        theme="primary" />
    )
  }
  const dataElements = datasetsState?.projects
  const renderData = datasetsState && datasetsState
  let columns = []
  if (dataElements && dataElements.length > 0) {
    const dataPropertyKeys = []
    dataElements.forEach(el => {
      Object.keys(el).forEach(el => dataPropertyKeys.push(el))
    })
    columns = returnColumnDataInfo(dataPropertyKeys)
  }

  // Needed to decide index for each row
  let rowCounter = 0;
  const rowRender = (tr, props) => {
    rowCounter++
    return tr
  }

  // Extra function needed before calling onFilterChangeHandler
  const docTypeFilterHandler = (type) => {
    const updatedDocTypes = [...selectedDocumentType]
    if (updatedDocTypes.includes(type)) {
      const typeIndex = updatedDocTypes.indexOf(type)
      updatedDocTypes.splice(typeIndex, 1)
      setSelectedDocumentType(updatedDocTypes)
      onFilterChangeHandler({ target: { value: updatedDocTypes } }, "documentType")
    } else {
      updatedDocTypes.push(type)
      setSelectedDocumentType(updatedDocTypes)
      onFilterChangeHandler({ target: { value: updatedDocTypes } }, "documentType")
    }
  }

  const handleExportNonCompletesCreate = () => {
    let hasErrors = false
    fetchPost(`/projects/${projectId}/surveys/${uploadSurveyId}/export-non-completes`, token, nonCompletesBody)
      .then(res => {
        if (res.status === 202) {
          dispatch({ type: 'UPDATE_FETCH_PROJECTS', payload: true })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Succesfully created dataset' } })
          setOpenNonCompletesModal(false)
        } else if (res.status === 400) {
          hasErrors = true
          return res.json()
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Something went wrong, please try again" } });
        }
      })
      .then(res => {
        if (hasErrors) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error } });
        }
      })
  }

  const handleHideModal = () => {
    setOpenNonCompletesModal(false)
    setNonCompletesBody({
      datasetName: "",
      includeStatus: {
        complete: true,
        screened: false,
        quotaFull: false,
        qualityFail: false,
        dropout: false,
        archived: false,
      }
    })
  }

  return (
    <React.Fragment>
      {
        showImportFileModal &&
        <EditOutcomesModal
          projectId={projectId}
          surveyId={uploadSurveyId}
          onHide={() => setShowImportFileModal(false)}
          type={itemClicked.type}
          datasetId={itemClicked.id} />
      }
      {
        openNonCompletesModal &&
        <ExportNonCompletesModal
          projectId={projectId}
          surveyId={uploadSurveyId}
          onHide={handleHideModal}
          nonCompletesBody={nonCompletesBody}
          setNonCompletesBody={setNonCompletesBody}
          handleExportNonCompletesCreate={handleExportNonCompletesCreate}
        />
      }
      <div className="row">
        <div className="col-md-12">
          <div className={`d-flex justify-content-end gap-md ${filterByType === "Date filter" ? "align-items-end" : "align-items-center"}`}>
            {/* Search/FilterTypes/FilterDateRange */}
            {
              filterByType === "Date filter" ?
                <div className='d-flex'>
                  <DateRangePicker
                    value={dateRange}
                    startDateInputSettings={{ className: "h-32" }}
                    endDateInputSettings={{ className: "dateRange-end h-32" }}
                    onChange={(e) => { setDateRange(e.value); onFilterChangeHandler(e, "dateCreated") }} />
                </div>
                :
                filterByType === "Type" ?
                  <div className="btn-group" role="group" aria-label="Document filtering">
                    {
                      availableDocumentTypes.map((availableType, typeIndex) => {
                        return (
                          <button
                            type='button'
                            key={typeIndex}
                            onClick={(e) => docTypeFilterHandler(availableType)}
                            className={`btn ${selectedDocumentType.includes(availableType) ? 'btn-secondary' : 'btn-outline-secondary'} text-dark`}>
                            <i className={`${iconByDocumentType[availableType]} mr-2 text-dark`} />
                            {availableType === 'workflows' ? 'Data operations' : availableType[0].toUpperCase() + availableType.substring(1)}
                          </button>
                        )
                      })
                    }
                  </div>
                  :
                  <input
                    type="text"
                    ref={searchInput}
                    name='Document name search'
                    className="form-control form-control-sm w-auto shadow-none h-32"
                    placeholder={filterByType === "Document name" ? "Document name" : "Created by"}
                    onChange={(e) => onFilterChangeHandler(e, filterByType === "Document name" ? "search" : "createdBy")}
                  />
            }
            {/* Filter buttons */}
            <div className="btn-group" role="group" aria-label="Document filtering">
              <button type='button' className="btn btn-outline-secondary text-dark shadow-none" onClick={() => clearFilterInputs()} title="Clear filter">
                <i className="fas fa-times" />
              </button>
              <DropDownButton
                iconClass="fas fa-filter"
                className="filter-dropdown btn btn-outline-secondary text-dark px-1 shadow-none"
                items={["Document name", "Created by", "Type", "Date filter"]}
                onItemClick={(e) => { setFilterByType(e.item); clearFilterInputs() }}
              />
            </div>
            {/* Projects sorting */}
            <div className="btn-group" role="group" aria-label="Document sorting">
              <button type='button' onClick={() => updateSortType('name')} className="btn btn-outline-secondary text-dark shadow-none">
                <span>Name</span>
                <i className={`fa ml-2 ${returnSortDirectionIcon('name', sortType)}`} />
              </button>
              <button type='button' onClick={() => updateSortType('date')} className="btn btn-outline-secondary text-dark shadow-none">
                <span>Date</span>
                <i className={`fa ml-2 ${returnSortDirectionIcon('date', sortType)}`} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Grid
        className="h-100 project-grid mt-3"
        data={renderData.result}
        skip={datasetsState.dataState.skip}
        take={datasetsState.dataState.take}
        total={datasetsState.projects.length}
        {...renderData.dataState}
        onDataStateChange={dataStateChange}
        pageable={true}
        pageSize={3}
        rowRender={rowRender}
      // scrollable="scrollable"
      >
        <GridNoRecords>
          <GridNoData secondaryMessage="Use the actions above to import datsets, add files and create surveys" />
        </GridNoRecords>
        {
          columns.map((el, key) => (
            <Column key={key} cell={props =>
              <ColumnValue
                {...props}
                token={token}
                data={el.data}
                history={history}
                setStatus={setStatus}
                returnIcon={returnTableIcon}
                dataPropertyKeys={el.keys}
                shareDetails={shareDetails}
                bulletValueAxis={bulletValueAxis}
                setDatasetBreadCrumb={setDatasetBreadCrumb}
                isLastEntry={renderData.result.data.length === rowCounter} />
            } />
          ))
        }
        <Column cell={props => {
          const itemType = props.dataItem.type === 'surveys' && props.dataItem.publishedVersions && props.dataItem.publishedVersions.length > 0 ? 'publishedSurveys' : null
          return (
            <td style={{ width: '10%' }}>
              <div className="d-flex justify-content-end">
                <DropDownButton
                  iconClass="fa fas fa-ellipsis-h"
                  buttonClass="btn btn-primary d-flex"
                  items={itemActions.document[itemType ? itemType : props.dataItem.type]}
                  onItemClick={(e) => selectAction(e, datasetsState ?
                    { id: props.dataItem.id, name: props.dataItem.name, type: props.dataItem.type, description: props.dataItem.description, tags: props.dataItem.tags, _etag: props.dataItem._etag, dataCollectionVersion: props.dataItem.dataCollectionVersion }
                    : { id: props.dataItem.id, name: props.dataItem.name }
                  )}
                />
              </div>
            </td>
          )
        }} />
      </Grid>
    </React.Fragment >
  )
}