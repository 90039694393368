import { process } from '@progress/kendo-data-query';

export const returnFilterData = (type, datasetsState, originalDatasetsState, e, originalData, searchValue, filterValue) => {

  let filterType = null
  let data = null
  let takeVal = 20
  let originalFilterData = null

  if (datasetsState) {
    originalFilterData = originalDatasetsState
  } else {
    originalFilterData = originalData
  }

  // When user selects a filter
  if (type === 'filter') {
    if (datasetsState) {
      filterType = 'type'
    } else {
      filterType = 'status'
    }

    // If the user has populated the search field before selecting a filter
    if (typeof searchValue !== "undefined" && searchValue !== null) {
      if (searchValue === '') {
        if (e.target.value === 'Status...' || e.target.value === 'Type...') {
          data = originalFilterData
        } else {
          const updatedData = originalFilterData.filter(item => item[filterType].toLowerCase().includes(e.target.value.toLowerCase()));
          data = updatedData.filter(item => item['name'].toLowerCase().includes(searchValue.toLowerCase()) || (item['description'] !== null && item['description'].toLowerCase().includes(searchValue.toLowerCase())));
        }
      } else {
        if (e.target.value === 'Status...' || e.target.value === 'Type...') {
          data = originalFilterData.filter(item => item['name'].toLowerCase().includes(searchValue.toLowerCase()) || (item['description'] !== null && item['description'].toLowerCase().includes(searchValue.toLowerCase())));
        } else {
          const updatedData = originalFilterData.filter(item => item[filterType].toLowerCase().includes(e.target.value.toLowerCase()));
          data = updatedData.filter(item => item['name'].toLowerCase().includes(searchValue.toLowerCase()) || (item['description'] !== null && item['description'].toLowerCase().includes(searchValue.toLowerCase())));
        }
      }
    } else {
      if (e.target.value === 'Status...' || e.target.value === 'Type...') {
        data = originalFilterData
      } else {
        data = originalFilterData.filter(item => item[filterType].toLowerCase().includes(e.target.value.toLowerCase()));
      }
    }
  }
  // When user is populating the search field
  else if (type === 'search') {
    // When the user has selected a filter before populating the search field
    if (filterValue && filterValue.value) {
      const updatedFilterType = filterValue.defaultValue === 'Status...' ? 'status' : 'type'
      if (e.target.value === '') {
        if (filterValue.value === 'Status...' || filterValue.value === 'Type...') {
          data = originalFilterData
        } else {
          data = originalFilterData.filter(item => item[updatedFilterType].toLowerCase().includes(filterValue.value.toLowerCase()));
        }
      } else {
        if (filterValue.value === 'Status...' || filterValue.value === 'Type...') {
          data = originalFilterData.filter(item => item['name'].toLowerCase().includes(e.target.value.toLowerCase()) || (item['description'] !== null && item['description'].toLowerCase().includes(e.target.value.toLowerCase())));
        } else {
          const updatedData = originalFilterData.filter(item => item[updatedFilterType].toLowerCase().includes(filterValue.value.toLowerCase()));
          data = updatedData.filter(item => item['name'].toLowerCase().includes(e.target.value.toLowerCase()) || (item['description'] !== null && item['description'].toLowerCase().includes(e.target.value.toLowerCase())));
        }
      }
    } else {
      data = originalFilterData.filter(item => item['name'].toLowerCase().includes(e.target.value.toLowerCase()) || (item['description'] !== null && item['description'].toLowerCase().includes(e.target.value.toLowerCase())));
    }
  } else if (type === "createdBy") {
    data = originalFilterData.filter(item => item["createdByName"].toLowerCase().includes(e.target.value.toLowerCase()));
  } else if (type === "dateCreated") {
    data = originalFilterData.filter(item => (item["createdUtc"] >= new Date(e.value.start).toISOString() && item["createdUtc"] <= new Date(e.value.end).toISOString()))
  } else if (type === "dateModified") {
    data = originalFilterData.filter(item => (item["lastModifiedUtc"] >= new Date(e.value.start).toISOString() && item["lastModifiedUtc"] <= new Date(e.value.end).toISOString()))
  } else if (type === "documentType") {
    data = originalFilterData.filter((a) => e.target.value.indexOf(a.type) > -1);
  } else if (type === "clear") {
    data = originalFilterData;
  }

  return {
    result: process(data.slice(0), { take: takeVal, skip: 0 }),
    dataState: { take: takeVal, skip: 0 },
    projects: data
  }
}