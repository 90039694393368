interface Props {
  user: TODO
  text: string
  id?: string
  route?: string
  onUserDropdownItemClick: (id: string, route?: string) => void
}

export const UserDropdownItem = ({ text, id, route, user, onUserDropdownItemClick }: Props) => {
  if (id === 'user-info') {
    return (
      <div className="dropdown-info-item user-dropdown-info">
        <div style={{ height: '3.5rem', width: '3.5rem', margin: '0 0 .75rem 0' }} className='user-info'>
          <h2 className='m-0'>{user.given_name[0]}</h2>
        </div>
        <h5 className="user-name">{`${user.given_name} ${user.family_name}`}</h5>
        <h6 className="user-email">{user.email}</h6>
      </div>
    )
  } if (id === 'company') {
    return (
      <div className="dropdown-info-item user-dropdown-info org d-flex flex-column">
        <h6 className='label'>Organization</h6>
        <div className="d-flex align-items-center">
          <span className="user-info organization mr-1">{user.originalCustomerName ? user.originalCustomerName[0] : user.customerName ? user.customerName[0] : '?'}</span>
          <h6 className="m-0">{user.originalCustomerName ? user.originalCustomerName : user.customerName ? user.customerName : ''}</h6>
        </div>
      </div>
    )
  } if (id === 'logout') {
    return (
      <button type="button" onClick={() => onUserDropdownItemClick(id)} className="dropdown-item">{text}</button>
    )
  }
  return (
    <button type="button" onClick={() => onUserDropdownItemClick(id ? id : '', route)} className="dropdown-item d-flex align-items-center">
      {text}
    </button>
  )
}