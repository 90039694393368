import React from 'react';
import { useDispatch } from 'react-redux';

export default ({ shareDetails, onShowShareProject }) => {
  const dispatch = useDispatch()

  const onDeleteUserClickHandler = (user) => {
    const userType = user.isSubAccountUser ? 'subAccount' : 'regularAccount'
    dispatch({ type: 'UPDATE_SHOW_DELETE_SHARED_USERS_MODAL', payload: { show: true, account: user.email, type: userType } })
  }

  if (shareDetails.shareUsers.length > 0) {
    return (
      <div className="alert alert-walr d-flex justify-content-between align-items-center mb-2 mx-4 shadow-sm">
        <div className="d-flex align-items-center">
          <i className="fas fa-users mr-3 text-primary"></i>
          <div>
            <p className="ml-2 mb-0 text-primary">This project is shared with:</p>
            <div className="px-1">
              {
                shareDetails.shareUsers.map((el, key) => (
                  <span key={key} style={{ fontWeight: '500' }} className={`badge badge-walr p-2 m-1`}>
                    <span>{el.email}</span>
                    <i
                      onClick={() => onDeleteUserClickHandler(el)}
                      className="fas fa-lg fa-times-circle cursor-pointer ml-2">
                    </i>
                  </span>
                ))
              }
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button onClick={() => onShowShareProject({ show: true, step: 'users' })} className="btn btn-walr d-flex align-items-center">
            <i className="fas fa-edit mr-2"></i>
            <span>Edit</span>
          </button>
        </div>
      </div>
    )
  } else {
    return (
      <div className="alert alert-warning border border-warning mb-2 mx-4" role="alert">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <i className="far fa-lg fa-info-circle mr-4"></i>
            <span>This project is not shared with any external users yet.</span>
          </div>
          <button onClick={() => onShowShareProject({ show: true, step: 'users' })} className="btn btn-success">
            <i className="fas fa-user-plus mr-2"></i>
            <span>Invite users</span>
          </button>
        </div>
      </div>
    )
  }
}